.landing {
    background: #0E1015;
    /* padding: 0% 7% 0% 7%; */
}

.mt{
    margin-top: 4%;
}

.p1 {
    color: #A38CBB;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.p2 {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}

.p3 {
    font-weight: 400;
    font-size: 40px;
    text-align: left;
}

.p4 {
    font-weight: 400;
    font-size: 16px;
}

.btn-align {
    justify-content: center;
    display: flex;
    align-items: center;
}

.typo1 {
    display: flex;
    gap: 20%;
    color: #06F2F8;
}

.span1 {
    font-size: 12px;
}

.span2 {
    font-size: 24px;
    font-weight: 600;
}

.card1 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #0A1C28;
    border-radius: 24px;
    color: #fff;
}

.tool_p {
    color: #A38CBB;
    font-weight: 700;
    font-size: 20px;
}

.tool_summary {
    color: #B4A5C3;
    font-weight: 400;
    font-size: 16px;
}

.gradient-text {
    background: linear-gradient(to right, #9873BD, #FFFFFF, #9873BD);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-weight: 400;
    font-size: 64px;
}

.gradient-text1 {
    background: linear-gradient(to right, #9873BD, #FFFFFF, #9873BD);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-weight: 400;
    font-size: 24px;
}

.p5 {
    font-weight: bolder;
    font-size: 66px;
}

.scrollWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.customScrollbar {
    position: relative;
    height: 450px;
    width: 8px;
    background-color: #662D91;
    border-radius: 20px;
    margin: 5%;
    overflow: hidden;
}

.customScrollbar1 {
    position: relative;
    height: 450px;
    width: 8px;
    background-color: #02FBFC;
    border-radius: 20px;
    margin: 5%;
    overflow: hidden;
}

.scrollThumb {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 150px;
    background-color: #02FBFC;
    border-radius: 20px;
}

.scrollThumb1 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 150px;
    background-color: #662D91;
    border-radius: 20px;
}

.scrollContainer {
    width: 250px;
    height: 500px;
    overflow-y: auto;
    position: relative;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

.scrollContainer1 {
    width: 560px;
    /* height: 500px; */
    overflow-y: auto;
    position: relative;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.05); */
}

.scrollContainer1::-webkit-scrollbar {
    display: none;
}

.scrollContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
}

.card {
    padding: 15px;
    text-align: center;
    background-color: #662D91;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.highlighted {
    background-color: #02FBFC;
    color: #0f0f1e;
}

.custom-link {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
    color: #0061FF;
    cursor: pointer;
}

.accordion-item {
    background-color: #121212;
    border-radius: 5px;
}

.accordion-item:hover {
    background-color: #1c1c1c;
}

.accordion-body {
    color: #fff;
    background-color: #121212;
    font-size: 0.9rem;
    border-top: 1px solid #333;
}